import axios from "axios"

export const getAllCompany=async()=>{
    try{
        const res=await axios.get("https://dev.apis.pococare.com/company/api/v1/company-service/offices")
        const getAllCompany=res.data.data;
        return getAllCompany.filter((companyData)=>companyData.companyId.includes("664303c4c5ff5140c542fd02"))


    }catch(e){
        alert(e?.response?.data?.message||"some technical issue")
    }
}

export const riseEmergency=async({company,mobile,lat,lng})=>{
    try{
        const res=await axios.post(`https://dev.apis.pococare.com/ticket/api/v1/company-qr/${company}/${mobile}/${lat}/${lng}/companyQr`)
        return res;
    }catch(e){
        alert(e?.response?.data?.message||"some technical issue");
    }
}