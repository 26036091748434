import { Box, Button, Text } from "@chakra-ui/react";

export const Card = ({ hospitalName, campusUniqueId, address ,handleClick}) => {
  return (
    <Box
  backgroundColor={"#f5f5f5"}
      padding={"1rem"}
      border="1px solid"
      boxShadow={"md"}
      borderRadius={"1rem"}
    //   position="relative"
      display="flex" // Use flexbox to manage layout
      flexDirection="column"
    >
      <Text fontWeight={"bold"}>CompanyName:{hospitalName}</Text>
      {/* <Text fontWeight={"bold"}>CampusId:{campusUniqueId}</Text> */}
      <Text fontWeight={"semibold"} >Address: {address}</Text>
      <Box display={"flex"} justifyContent={"center"} mt="auto">
      <Button
        onClick={handleClick}
        colorScheme="red"
        border={"1px"}      
      >
       Raise Emergency
      </Button>

      </Box>
    </Box>
  );
};
