import { Button, HStack, Input } from "@chakra-ui/react";

export const SearchBar = ({handleChange,handleSearch}) => {
  return (
    <>
      <HStack 
   margin={"1rem"}>
        <Input border={"1px"} placeholder="Enter Clinic Name"  onChange={handleChange} />
        <Button border={"1px"} colorScheme="green" onClick={handleSearch}>Search</Button>
      </HStack>
    </>
  );
};
