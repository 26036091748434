import React, { useEffect, useState } from "react";
import { Card } from "../components/Card";
import { Button, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { getAllCompany, riseEmergency } from "./action";
import { SearchBar } from "../components/SearchBar";
import { ShowAlert } from "../components/ShowAlert";

export const TrioCogLanding = () => {
  const [companies, setCompanies] = React.useState([]);
  const [searchRes,setSearchRes]=useState("")
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyToAlert, setCompanyToAlert] = useState(null);
// const handleClick = async(company) => {

// //   console.log("clicked",company);
//   await riseEmergency({company:company.campusUniqueId,mobile:company.officeContacts[0].mobile.number
//     ,lat:company.cords.lat,lng:company.cords.lon});
//     alert("emergency raised successfully Please wait, Our team will reach out to you soon!");
// //   console.log(res);
// }

const handleClick = async (company) => {
  // Store the company data to use later if the user confirms
  setCompanyToAlert(company);
  // Open the modal
  setIsModalOpen(true);
};

const handleConfirm = async () => {
  // Close the modal
  setIsModalOpen(false);

  if (companyToAlert) {
    // Perform the emergency request using companyToAlert data
    await riseEmergency({
      company: companyToAlert.campusUniqueId,
      mobile: companyToAlert.officeContacts[0].mobile.number,
      lat: companyToAlert.cords.lat,
      lng: companyToAlert.cords.lon
    });

    // Show success message
    ShowAlert("success","Emergency raised successfully. Please wait, our team will reach out to you soon!");
  }
};

const handleCancel = () => {
  // Close the modal without taking any action
  setIsModalOpen(false);
  // Reset the stored company data
  setCompanyToAlert(null);
};
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchRes(value);
    if (value) {
      const filtered = companies.filter((company) =>
        company.companyName.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCompanies(filtered);
    } else {
      setFilteredCompanies(companies);
    }
  }
//   console.log('filteredCompanies:', filteredCompanies);
  const handleSearch = () => {
    if (searchRes) {
      const filtered = companies.filter((company) =>
        company.companyName.toLowerCase().includes(searchRes.toLowerCase())
      );
      setFilteredCompanies(filtered);
    } else {
      setFilteredCompanies(companies);
    }
  }
  useEffect(() => {
    (async () => {
      const res = await getAllCompany();
      setCompanies(res);
      setFilteredCompanies(res);

    })();
  }, []);
  return (
    
    <div style={{ width: "90%", margin: "auto",  }}>
        <SearchBar handleChange={handleChange} handleSearch={handleSearch}/>
      <Grid mt={"1rem"} templateColumns={{md:"repeat(3, 1fr)",lg:"repeat(4, 1fr)",xl:"repeat(5, 1fr)"}} gap={6}>
        {filteredCompanies.map((company) => (
          <Card
            key={company.companyName}
            campusUniqueId={company.campusUniqueId}
            address={company.address}
            hospitalName={company.companyName}
            handleClick={()=>handleClick(company)}

          />
        ))}
        {/* <Card /> */}
      </Grid>
      <Modal isOpen={isModalOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Emergency</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Text fontWeight={"500"}>

            Are you sure you want to raise an emergency for {companyToAlert && companyToAlert.companyName}?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleConfirm}>
              Yes, Raise Emergency
            </Button>
            <Button onClick={handleCancel}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
