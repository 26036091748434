// import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { TrioCogLanding } from './pages/TrioCogLanding';
import { Header } from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <ChakraProvider>
    <div >
      <ToastContainer autoClose={3000} />
      <Header/>
      <TrioCogLanding/>      
    </div>
    </ChakraProvider>
  );
}

export default App;
