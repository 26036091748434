import { Box, Image } from "@chakra-ui/react";

export const Header = () => {
    return (
        <div style={{ width: "100%" ,backgroundColor:"#01294E" ,height:"70px" }}>
            <Box   textAlign={"center"} display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"} >
                <Box p={"1rem"}>
             <Image margin={"auto"} src="/NewLogo.png"  alt="poco logo" width={"80px"} h={"100%"} />

                </Box>
                <Box p={"1rem"}>
                <Image margin={"auto"} src="/Tricog-Logo.webp" alt="tricog logo" width={"80px"} h={"100%"} />

                </Box>
            </Box>
        </div>
    );
}